import { MappableModel } from '@se-po/shared-data-access-models'
import {
  CommonResources as com,
  CalendarServiceResources as cs
} from 'se-resource-types'

export type Principal = cs.Principal & { extended_attributes: any }

export type EventGameDetails = {
  originator_id?: string
  originator_type?: string
  originator_system?: string
  status?: string
  team1?: cs.Team
  team2?: cs.Team
  team_1?: cs.Team
  team_2?: cs.Team
}

export interface SePlayVideo {
  description?: null
  title?: string
  originator_system: string
  url: string
  status: string
}

export class Event extends MappableModel implements cs.Event {
  public _links: com.HAL_Links[]
  public all_day_event: boolean
  public allow_rsvp: boolean
  public created_at: string
  public description: string
  public end_date_time: string
  public event_attendees: any[]
  public event_type: string
  public game_details: EventGameDetails
  public id: string
  public local_timezone: string
  public location: string
  public location_address: string
  public location_description: string
  public location_url: string
  public org_id: number
  public originator_id: string
  public originator_system: 'Ngin' | 'StatNgin'
  public originator_type: string
  public principals: Principal[]
  public sport_key: string
  public start_date_time: string
  public status: 'Canceled' | 'Draft' | 'Postponed' | 'Scheduled'
  public tbd_time: boolean
  public title: string
  public updated_at: string
  public venue_id: string
  public venue_url: string
  public videos: SePlayVideo[]

  public location_place_id: string | number

  public get isGame(): boolean {
    return this.event_type === 'game'
  }

  public get isEvent(): boolean {
    return this.event_type === 'event'
  }

  public get gameStatus(): string | undefined {
    return ((this.game_details || {})).status
  }

  public get cardEventType(): string {
    const capitalizedEventType = `${this.event_type.charAt(0).toUpperCase()}${this.event_type.slice(1)}`
    const type = `${capitalizedEventType}Card`
    return type
  }
}
