import { TranslateService } from '@ngx-translate/core'
import { DateTime } from 'luxon'
import { CalendarServiceResources as cs } from 'se-resource-types'

import { Event, EventGameDetails, Persona, Principal } from '../models'

import { environment } from '@se-po/shared-environments'
import { SeEnvironmentUrlService } from 'se-fe-auth-support'
import { RsvpView } from './rsvp-view'
const REDIRECT_BASE = 'v3/doorway_redirect'

export interface DayView {
  day: string
  events: EventView[]
}

export interface SePlayVideo {
  description?: null
  title?: string
  originator_system: string
  url: string
  status: string
}
export class EventView {
  /**
   * used to track the index of the event in the schedule list
   */
  public index: string
  private seApi: string
  private _allRsvps: any[]
  private _eventEndTime: DateTime
  private _eventStartTime: DateTime
  private _gameTitle: string
  private _isEligibleForRsvps: boolean
  private _rsvps: any[]
  private _tags: any[]
  private eventUrl: string

  constructor(
    public event: Event,
    public personas: Persona[],
    private translate: TranslateService,
    private urlService: SeEnvironmentUrlService
  ) {
    this.seApi = urlService.getApiUrl()
    this.eventUrl = this.seApi + REDIRECT_BASE
  }

  public get id(): string | number {
    return this.event.id
  }

  public get title(): string {
    return this._eventType() === 'Ngin.game' ? this.gameTitle() : this.event.title
  }

  public get titleAttributes(): { href?: string } {
    if (this.nginGameOrEvent()) {
      return {
        href: this.nginEventUrl()
      }
    } else if (this.statNginGameOrEvent()) {
      return {
        href: this.statNginEventUrl()
      }
    } else {
      return { }
    }
  }

  public get subtitle(): string {
    if (!(this.nginGameOrEvent() || this.statNginGameOrEvent())) return ''
    const principal = this.getFirstPrincipal()
    return (principal?.extended_attributes?.name || '') as string
  }

  public get startDateTime(): string {
    return this.event.start_date_time
  }

  public get endDateTime(): string {
    return this.event.end_date_time
  }

  public get timeZone(): string {
    return this.event.local_timezone
  }

  public get zone(): string {
    return this.event.local_timezone
  }

  public get allDay(): boolean {
    return this.event.all_day_event
  }

  public get allDayEvent(): boolean {
    return this.event.all_day_event
  }

  public get timeTbd(): boolean {
    return this.event.tbd_time
  }

  public get location(): string {
    let location = this.event.location_address
    if (this.event.location_description) location += ` - ${this.event.location_description}`
    return location
  }

  public get locationAttributes(): any {
    return this.getLocationAttributes()
  }

  public get teamColor(): string {
    if (!this.nginGameOrEvent()) return ''
    const principal = this.getFirstPrincipal()
    return principal && principal.extended_attributes ? `#${principal.extended_attributes.primary_color as string}` : ''
  }

  public get rsvps(): any {
    return this.getRsvps()
  }

  public get eventType(): string {
    return this.event.event_type
  }

  public get tags(): any[] {
    return this.getTags()
  }

  public get shortDate(): string {
    return this.eventStartTime().toISODate()
  }

  public get videos(): SePlayVideo[] {
    return this.event.videos
  }

  // --------------------
  // private
  // --------------------

  private get timezoneOpts(): { zone: string} {
    return this.event.local_timezone ? { zone: this.event.local_timezone } : undefined
  }

  // not to be confused with this.eventType which is this.event.event_type
  // likely returns Ngin.event || Ngin.game
  private _eventType(): string {
    return this.gameDetailsOriginator(this.event.game_details) || this.originator(this.event)
  }

  private gameDetailsOriginator(data: EventGameDetails): string {
    if (!data.originator_type) return ''
    return this.originator(data)
  }

  private originator(data: any): string {
    if (!data || !data.originator_system) return ''
    return [data.originator_system, data.originator_type].filter(x => !!x).join('.')
  }

  private nginGameOrEvent(): boolean {
    return ['Ngin.event', 'Ngin.game'].includes(this._eventType())
  }

  private statNginGameOrEvent(): boolean {
    return this._eventType().startsWith('StatNgin')
  }

  private gameTitle(): string {
    if (this._gameTitle) return this._gameTitle

    const game: EventGameDetails = this.event.game_details
    const team1 = game.team_1 || {} as cs.Team
    const team2 = game.team_2 || {} as cs.Team
    const home = team1.is_home_team ? team1 : team2
    const away = team1.is_home_team ? team2 : team1

    if (home.name && away.name) return this.translate.instant('EVENT_LIST_GAME_TITLE.away_at_home', { home: home.name, away: away.name })
    if (home.name) return this.translate.instant('EVENT_LIST_GAME_TITLE.tbd_at_home', { home: home.name })
    if (away.name) return this.translate.instant('EVENT_LIST_GAME_TITLE.away_at_tbd', { away: away.name })

    this._gameTitle = this.translate.instant('EVENT_LIST_GAME_TITLE.default')

    return this._gameTitle
  }

  private nginEventUrl(): string {
    const eventId = this.event.id
    const teamId = this.getFirstPrincipal()?.principal_originator_id || ''
    return `${this.eventUrl}?originator_system=Ngin&originator_type=team_instance&originator_id=${teamId}`
      + `&context_tags=${this.eventType}:${eventId}`
  }

  private statNginEventUrl(): string {
    const eventId = this.event.id
    const teamId = (this.getFirstPrincipal()?.id || '')
    return `${environment.app}teams/${teamId}/schedule/${this.eventType}s/${eventId}`
  }

  private getFirstPrincipal(): Principal {
    if (!this.event.principals || this.event.principals.length === 0) {
      return undefined
    }
    return this.event.principals[0]
  }

  private getLocationUrl(): string {
    if (this.event.location_url.indexOf('http') !== 0) {
      return encodeURI(`https://${this.event.location_url}`)
    }
    else {
      return encodeURI(this.event.location_url)
    }
  }

  private eventStartTime(): DateTime {
    if (!this._eventStartTime) {
      this._eventStartTime = DateTime.fromISO(this.event.start_date_time, this.timezoneOpts)
    }
    return this._eventStartTime
  }

  private eventEndTime(): DateTime | undefined {
    if (!this.event.end_date_time) return undefined

    if (!this._eventEndTime) {
      this._eventEndTime = DateTime.fromISO(this.event.end_date_time, this.timezoneOpts)
    }
    return this._eventEndTime
  }

  private getLocationAttributes(): { href?: string; target?: string } {
    if (this.event.location_url) {
      return { href: this.getLocationUrl(), target: '_blank' }
    }
    else if (this.event.location_address) {
      const placeParam = this.event.location_place_id ? `&query_place_id=${this.event.location_place_id}` : ''
      if (!placeParam) return undefined

      const mapUrl = `https://www.google.com/maps/search/?api=1&query=${this.event.location_address}${placeParam}`
      return { href: encodeURI(mapUrl), target: '_blank' }
    }
  }

  private getRsvps(): any[] {
    if (this._rsvps) return this._rsvps
    if (!this.isEligibleForRsvps()) return []

    const allRsvps: RsvpView[] = this.event.event_attendees
      .map(rsvp => new RsvpView(rsvp, this.event))
    this._allRsvps = allRsvps
    allRsvps.forEach(rsvp => {
      const persona: Persona = new Persona(this.personas.find(p => p.id.toString() === rsvp.personaId))
      if (persona) {
        rsvp.identity = persona.fullName
        const croppedImage = persona.profile_images?.find(i => i.image_type === 'crop_icon')
        if (croppedImage && croppedImage.url) {
          rsvp.imgUrl = croppedImage.url
        }
      }
    })

    this._rsvps = allRsvps.filter(rsvp => !!rsvp.identity)
      .sort((a, b) => a.identity > b.identity ? 1 : -1)

    return this._rsvps
  }

  private getTags(): any[] {
    if (this._tags) return this._tags

    const eventStatusTags = {
      delayed: { value: this.translate.instant('EVENT_LIST_TAGS.Delayed'), color: 'warn' },
      postponed: { value: this.translate.instant('EVENT_LIST_TAGS.Postponed'), color: 'warn' },
      cancelled: { value: this.translate.instant('EVENT_LIST_TAGS.Cancelled'), color: 'alert' },
      in_progress: { value: this.translate.instant('EVENT_LIST_TAGS.InProgress'), color: 'success' },
      completed: { value: this.translate.instant('EVENT_LIST_TAGS.Completed'), color: 'gray' },
      unofficial_final: { value: this.translate.instant('EVENT_LIST_TAGS.UnofficialFinal'), color: 'gray' }
    }

    const tags = []
    let status = ''

    if (this.event.isGame && this.event.gameStatus) {
      status = this.event.gameStatus.toLowerCase()
    }
    else if (this.event.isEvent && this.event.status) {
      status = this.event.status.toLowerCase()
    }

    if (eventStatusTags[status]) {
      tags.push(eventStatusTags[status])
    }
    else if (this.eventInProgress()) {
      tags.push(eventStatusTags.in_progress)
    }

    this._tags = tags

    return this._tags
  }

  private isEligibleForRsvps(): boolean {
    if (this.personas.length === 0 ||
      this.eventInPast() ||
      !this.event.event_attendees ||
      this.event.event_attendees.length === 0) {
        this._isEligibleForRsvps = false
      return false
    }
    else {
      this._isEligibleForRsvps = true
      return true
    }
  }

  private eventInPast(): boolean {
    const eventTime = DateTime.fromISO(this.event.start_date_time, this.timezoneOpts)
    const now = DateTime.local()

    return now > eventTime
  }

  private eventInProgress(): boolean  {
    let inProgress = false
    const now = DateTime.local()

    if (this.event.tbd_time) return false

    if (this.event.all_day_event) {
      // does not apply to games
      const eventStartTime = this.eventStartTime()

      const daystart = eventStartTime.set({ hour: 0, minute: 0, second: 0 })
      const dayEnd = eventStartTime.set({ hour: 23, minute: 59, second: 59 })
      inProgress = daystart < now && dayEnd > now
    }
    else {
      inProgress = this.eventStartTime() < now && this.eventEndTime() > now
    }

    return inProgress
  }
}
